<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>City Name</label>
                <InputText v-model="forms.city_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.city_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.city_name" >{{ errorAdd.city_name[0] }}</small >
            </div>
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory</label>
                    <Dropdown dataKey="territory_id" v-model="forms.territory_id" :loading="loadingDropdownTerritory"
                        :options="dataDropdownTerritory" :class="{ 'p-invalid': errorAdd.territory_id }"
                        optionLabel="label" optionValue="territory_id" placeholder="Select Territory"
                        :filter="true" :showClear="true" @filter="searchDropdownTerritory($event, 'add')"
                    />
                    <small class="p-invalid" style="color: red" v-if="errorAdd.territory_id">{{
                        errorAdd.territory_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownTerritory: false,

            // dataDropdown
            dataDropdownTerritory: null,

            // addNew
            forms: {
                city_name: null,
                territory_id: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownTerritory('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // DROPDOWN
        searchDropdownTerritory(event, purpose, valueEdit){
            setTimeout(() => {
                if(valueEdit){
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if(purpose == "add"){
                    this.loadingDropdownTerritory = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory3',
                    params: {
                        "search" : valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if(purpose == "add"){
                        this.dataDropdownTerritory = res.data.data;
                        this.loadingDropdownTerritory = false;
                    }else if(purpose == null){
                        this.dataDropdownTerritory = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/city/create',
                data: {
                    "city_name": this.forms.city_name,
                    "territory_id": this.forms.territory_id,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.city_name = null;
            this.forms.territory_id = null;
        },
    }
}
</script>